<template>
    <div>backClick
        <LiefengContent>
            <template v-slot:title>栏目管理</template>
            <template v-slot:toolsbarRight>
                <Form :label-colon="true" :inline="true" class="search" >
                    <FormItem>
                        <Input v-model.trim="searchData.sysColumnCode"  placeholder="请输入栏目编码" style="width: 160px;margin-right:10px"></Input>
                    </FormItem>
                    <FormItem>
                        <Input v-model.trim="searchData.columnName"  placeholder="请输入栏目名称" style="width: 160px;margin-right:10px"></Input>
                    </FormItem>
                    <FormItem>
                        <Input v-model.trim="searchData.extendCode"  placeholder="请输入属性值" style="width: 160px;margin-right:10px"></Input>
                    </FormItem>
                    <FormItem>
                        <Select transfer v-model="searchData.clientType" style="width:200px;text-align:left;margin-right:10px" placeholder="请选择客户端类型">
                            <Option v-for="(item,index) in clientList" :value="item.dictKey" :key="index">{{ item.dictValue }}</Option>
                        </Select>    
                    </FormItem>    
                    <!-- <FormItem>
                        <Cascader transfer change-on-select v-model="searchData.systemId" style="width:200px;margin-right:10px;display:inline-block;text-align:left" :data="cascaderList" :load-data="loadData"></Cascader>
                    </FormItem>    -->
                        
                    <Button type="primary" icon="ios-search" @click="searchBtn" style="margin-right: 10px">查询</Button>
                    <Button type="success" @click="resetBtn" icon="ios-refresh" style="margin-right: 10px">重置</Button>
                    <Button
                        icon="ios-add"
                        type="primary"
                        @click="addChange"
                    >新增</Button>
                </Form>
            </template>
            <template v-slot:contentArea>
                <LiefengTable
                :talbeColumns="talbeColumns"
                :tableData="tableData"
                :loading="loading"
                :fixTable="true"
                :curPage="page"
                :total="total"
                :pagesizeOpts="[20, 30, 50, 100]"
                :page-size="pageSize"
                @hadlePageSize="hadlePageSize"
                ></LiefengTable>
            </template>
            </LiefengContent>
             <!--新增修改-->
            <LiefengModal
                :title="titleName"
                :value="contrastStatus"
                @input="contrastFn"
                :fullscreen="false"
                width="500px"
                height="600px"
                >
                <template v-slot:contentarea>
                    <FormAdd ref="form" :editType='editType' :titleName="titleName" :showNum="showNum" :formData="formData"></FormAdd>
                </template>
                <template v-slot:toolsbar>
                    <Button type="info" style="margin-right:10px" @click="contrastFn(false)">取消</Button>
                    <Button type="primary" @click="contrastSave">确定</Button>
                </template>
            </LiefengModal>
            <LiefengModal
                :title="`${contextName}栏目已关联一下${contextNum}个菜单`"
                :value="contextMenuStatus"
                @input="changeContextMenuStatus"
                :fullscreen="false"
                width="500px"
                height="600px"
                >
                <template v-slot:contentarea>
                    <Tree 
                        :data="menuTree" 
                        :load-data="loadMenuData" 
                        show-checkbox>
                    </Tree>
                </template>
                <template v-slot:toolsbar>
                    <Button type="info" @click="changeContextMenuStatus(false)">关闭</Button>
                    <!-- <Button type="primary" @click="saveContextMenu">确定</Button> -->
                </template>
            </LiefengModal>
    </div>  
</template>

<script>
import LiefengContent from "@/components/LiefengContent3";
import LiefengTable from "@/components/LiefengTable";
import LiefengModal from "@/components/LiefengModal";
import FormAdd from './childrens/form'
export default {
    components:{LiefengContent,LiefengTable,LiefengModal,FormAdd},
    data(){
        return{
           // table表格数据
             talbeColumns: [
                   {
                       title: "栏目名称",
                       key: "columnName",
                       minWidth: 200,
                       align: "center"
                   },
                   {
                       title: "栏目编码",
                       key: "sysColumnCode",
                       minWidth: 200,
                       align: "center"
                   },
                   {
                       title: "栏目类型",
                       key: "columnType",
                       minWidth: 200,
                       align: "center"
                   },
                   {
                       title: "属性值",
                       key: "extendCode",
                       minWidth: 200,
                       align: "center"
                   },
                   {
                       title: "客户端类型",
                       key: "clientType",
                       minWidth: 200,
                       align: "center"
                   },
                   {
                       title: "功能类型",
                       minWidth: 200,
                       align: "center",
                       render: (h, params) => {
                         for (var i = 0; i < this.$refs.form.functionList.length; i++) {
                           if (params.row.functionId == this.$refs.form.functionList[i].functionId) {
                             return h("div", {}, this.$refs.form.functionList[i].functionName)
                           }
                         }
                       }
                   },
                   {
                        title: "操作",
                        align: "center",
                        fixed: "right",
                        width: 180,
                        render: (h, params) => {
                                return h(
                                "div",
                                {
                                    style: {
                                    textAlign: "center"
                                    }
                                },
                                [  
                                    h(
                                    "Dropdown",
                                    {
                                        props: {
                                        transfer: true
                                        }
                                    },
                                    [
                                        h(
                                        "Button",
                                        {
                                            props: {
                                                type: "success",
                                                size: "small",
                                                icon: "ios-arrow-down"
                                            }
                                        },
                                        "操作"
                                        ),
                                        h(
                                        "DropdownMenu",
                                        {
                                            slot: "list"
                                        },
                                        [
                                           h(
                                            "DropdownItem",
                                            {
                                                nativeOn: {
                                                    click: () => {
                                                        this.editType = 'edit'
                                                        this.getDetail(params.row.sysColumnId)
                                                    }
                                                },
                                                style: {
                                                    textAlign: "center"
                                                }
                                            },
                                            "修改"
                                            ),
                                            h(
                                            "DropdownItem",
                                            {
                                                nativeOn: {
                                                    click: () => {
                                                    
                                                        this.$Modal.confirm({
                                                        title: "温馨提示",
                                                        content:
                                                            "<p>您正在删除当前信息，此操作不可逆，是否继续</p>",
                                                            onOk: () => {
                                                                this.deleteRow(params.row.sysColumnId)
                                                            
                                                            }
                                                        });
                                                    }
                                                },
                                                style: {
                                                    textAlign: "center"
                                                }
                                            },
                                            "删除"
                                            ),
                                            // h(
                                            // "DropdownItem",
                                            // {
                                            //     nativeOn: {
                                            //         click: () => {
                                            //             let data = params.row
                                            //             this.titleName = '新增子栏目'
                                            //             this.contrastStatus = true   
                                            //             ++this.showNum
                                            //             this.formData = {
                                            //                 columnName:'',
                                            //                 extendCode:'',
                                            //                 columnType:data.columnType,
                                            //                 parentId:data.sysColumnId,
                                            //                 systemId:data.systemId,
                                            //                 functionId: data.functionId,
                                            //             }
                                            //             this.functionList.map(item =>{
                                            //                 if(item.dictValue == data.functionType){
                                            //                     this.formData.functionType = item.dictKey
                                            //                 }
                                            //             })
                                            //         }
                                            //     },
                                            //     style: {
                                            //         textAlign: "center"
                                            //     }
                                            // },
                                            // "新增子栏目"
                                            // ),
                                            h(
                                            "DropdownItem",
                                            {
                                                nativeOn: {
                                                    click: () => {
                                                        let data = params.row
                                                        this.functionList.map(item =>{
                                                            if(item.dictValue == data.functionType){
                                                                this.formData.functionType = item.dictKey
                                                            }
                                                        })
                                                        this.$router.push(`/columnconfigtreeindex?columnName=${params.row.columnName}&sysColumnId=${params.row.sysColumnId}&columnType=${params.row.columnType}&systemId=${params.row.systemId}&functionType=${this.formData.functionType}`)
                                                    }
                                                },
                                                style: {
                                                    textAlign: "center"
                                                }
                                            },
                                            "子栏目管理"
                                            ),
                                            h(
                                            "DropdownItem",
                                            {
                                                nativeOn: {
                                                    click: () => {
                                                        this.contextMenu(params.row)
                                                    }
                                                },
                                                style: {
                                                    textAlign: "center"
                                                }
                                            },
                                            "已关联菜单"
                                            ),
                                        ]
                                        )
                                    ]
                                    )
                                ]
                                );
                         }
                        },
             
             ],
             tableData:[],
             loading:false,
             page:1,
             pageSize:20,
             total:0,
             currentPage:0,

            // 新增修改模态框状态
            contrastStatus:false,
            titleName:'',
            showNum:0,
            formData:{},

            // 查询参数
            searchData:{
                systemId:[],
                sysColumnCode:'',
                columnName:'',
                // columnType:'',
                extendCode:''
            },
            
            // 存放数字字典接口数据
            functionList:[],
            businessList:[],

            // 查询数据的级联选择器
            cascaderList:[],
            
            //关联菜单
            contextMenuStatus:false,
            contextName:'',
            contextNum:0,

            //菜单数
            menuTree:[],
            checkMenuTree:[],

            editType:'',

            //客户端类型
            clientList:[],


        }
    },
    async created() {
       // await this.getDictTye('FUNCTION_TYPE','functionList')
       await this.getDictTyet('COLUMN_CLIENT_TYPE','clientList')
       await this.getDictTye('MENU_BUSINESS','businessList')
       await this.getList()
       this.getCasacader()
        
    },
    methods:{
        // 方法部分
        backClick(){
            this.$router.push('/authCloudSystem')
        },

        // 切换分页的方法
        hadlePageSize(val){
            this.page = val.page
            this.pageSize = val.pageSize
            this.getList()
        },
        // 点击了新增按钮
        addChange(){
            this.editType = 'add'
            this.contrastStatus = true   
            this.titleName = '新增栏目'
            ++this.showNum
             this.formData = {
                    clientType:'',
                    columnType:'',
                    functionId:[],
                    systemId:this.$route.query.systemId,
                    columnName:'',
                    extendCode:'',
                    configJson:''
                }
        },
        // 关闭弹窗的方法
        contrastFn(status){
            this.contrastStatus = status          
        },
        // 新增修改保存按钮
        contrastSave(){
            // this.$refs.form.$refs.form.validate((valid)=>{
                // if(valid){
                    console.log(this.formData.systemId);
                    if(!this.formData.columnName){
                        this.$Message.warning({
                            content:'请填写栏目名称',
                            background:true
                        })
                        return
                    }
                    if(!this.formData.columnType){
                        this.$Message.warning({
                            content:'请填写栏目类型',
                            background:true
                        })
                        return
                    }
                    if(!this.formData.systemId || this.formData.systemId.length == 0){
                        this.$Message.warning({
                            content:'请选择系统ID',
                            background:true
                        })
                        return
                    }
                //   if(!this.formData.functionId || this.formData.functionId.length == 0){
                //     this.$Message.warning({
                //       content:'请选择功能ID',
                //       background:true
                //     })
                //     return
                //   }
                  if(!this.formData.extendCode){
                        this.$Message.warning({
                            content:'请填写属性值',
                            background:true
                        })
                        return
                    }
                  if(!this.formData.clientType){
                    this.$Message.warning({
                      content:'请选择客户端类型',
                      background:true
                    })
                    return
                  }
                    console.log(this.formData)
                    if(this.formData.systemId instanceof Array){
                        this.formData.systemId = this.formData.systemId[this.formData.systemId.length - 1]
                    }
                    if(this.titleName == '新增栏目'){
                        this.formData.parentId = '0'
                        this.addRow()
                    }else if(this.titleName == '修改栏目'){
                        this.updateRow()
                    }else if(this.titleName == '新增子栏目'){
                        this.addTreeRow()
                    }   
                // }
            // })
            
        },   
        // 查询按钮
        searchBtn(){
            this.page = 1
            this.getList()
        },
        // 重置按钮
        resetBtn(){
            this.searchData={
                systemId:[],
                sysColumnCode:'',
                columnName:'',
                extendCode:'',
                clientType:'',
            },
            this.page = 1
            this.getList()
        },
        // callback级联函数
          loadData (item, callback) {
               item.loading = true
               this.$get('/gx/pc/organization/orgSystem/selectSystemList',{
                    keyword:'',
                    parentId:item.value
                }).then(res=>{
                    if(res.code == 200){
                        let arr = res.dataList.map(item=>{
                            return {
                                value:item.systemId,
                                label:item.appName,
                                parentId:item.parentId,
                                appCode:item.appCode
                            }
                        })
                        item.loading = false
                        item.children = arr
                        callback(item.children)
                    }else{
                        callback([])
                    }
                })
                
         },
        // 接口部分
       async getList(){
            console.log(this.searchData.systemId)
            console.log(this.$route.query.systemId)
            this.loading = true
            await this.$get('/auth/api/auth/pc/systemColumn/selectByPage',{
                page:this.page,
                pageSize:this.pageSize,
                ...this.searchData,
                parentId:0,
                systemId:this.$route.query.systemId
            }).then(res=>{
                this.loading = false
                if(res.code == 200){
                    if(res.dataList && res.dataList.length != 0){
                        res.dataList.map(item =>{
                          this.functionList.map(items =>{
                              if(items.dictKey == item.functionType){
                                  item.functionType = items.dictValue
                              }
                          })  
                        })
                    }
                    this.tableData = res.dataList
                    this.total = res.maxCount
                    this.page = res.currentPage
                }else{
                    this.$Message.error({
                        content:'获取列表失败',
                        background:true
                    })
                    return
                }
            })
        },
        //获取级联
        getCasacader(){
            this.$get('/gx/pc/organization/orgSystem/selectSystemList',{
                keyword:'',
                parentId:0
            }).then(res=>{
                if(res.code == 200){
                    this.cascaderList = res.dataList.map(item=>{
                        if(item.hasChild == '2'){
                            return {
                                value:item.systemId,
                                label:item.appName,
                                children:[],
                                loading: false,
                                parentId:item.parentId,
                                appCode:item.appCode
                            }
                        }else{
                            return {
                                value:item.systemId,
                                label:item.appName,
                                parentId:item.parentId,
                                appCode:item.appCode
                            }
                        }
                    })
                }else{
                    this.$Message.error({
                        content:'获取级联选择失败',
                        background:true
                    })
                    return
                }
            })
        },
        // 数字字典接口
       async getDictTye(dictType,list){
           await this.$get("/datamsg/api/common/sys/findDictByDictType", {
                dictType
            }).then(res => {
                if(res.code == 200) {
                this[list]= res.dataList
                }
            })
        },
        async getDictTyet(dictType,list){
            await this.$get("/dictlist/lf-common/api/lfcommon/pc/dict/selectDictDataList", {
                dictType,
                appCode:""
            }).then(res => {
                if(res.code == 200) {
                    this[list]= res.dataList
                }
            })
        },
        // 删除接口
        deleteRow(columnId){
            this.$post('/auth/api/auth/pc/systemColumn/deleteById',{
                columnId
            }).then(res=>{
                if(res.code == 200){
                    this.$Message.success({
                        content:'删除成功',
                        background:true
                    })
                    
                    this.getList()
                }else{
                    this.$Message.error({
                        content:'删除失败',
                        background:true
                    })
                    return
                }
            })
        },
        // 新增接口
        addRow(){
            var configJson = this.formData.configJson
              if (configJson && typeof configJson == 'string') {
                try {
                  var obj = JSON.parse(configJson);
                  if (typeof obj != 'object' || !obj) {
                    this.$Message.error({
                      background: true,
                      content: "JSON格式不正确！"
                    });
                    return
                  }
                } catch (e) {
                  this.$Message.error({
                    background: true,
                    content: "JSON格式不正确！"
                  });
                  return
                }
              }
            this.$post('/auth/api/auth/pc/systemColumn/add',{
                ...this.formData,
                functionId:this.formData.functionId[this.formData.functionId.length - 1]
            },{'Content-Type':'Application/json'}).then(res=>{
                if(res.code == 200){
                    this.$Message.success({
                        content:'新增成功',
                        background:true
                    })
                    this.page = 1
                    this.searchData = {}
                    this.contrastStatus = false
                    this.getList()
                }else{
                    this.$Message.error({
                        content:'新增失败',
                        background:true
                    })
                    return
                }
            })
        },
        // 新增子栏目
        addTreeRow(){
            this.$post('/auth/api/auth/pc/systemColumn/add',{
                ...this.formData,
            },{'Content-Type':'Application/json'}).then(res=>{
                if(res.code == 200){
                    this.$Message.success({
                        content:'新增成功',
                        background:true
                    })
                    this.contrastStatus = false
                    this.getList()
                }else{
                    this.$Message.error({
                        content:'新增失败',
                        background:true
                    })
                    return
                }
            })
        },
        // 获取详情接口
        getDetail(columnId){
            this.$get('/auth/api/auth/pc/systemColumn/selectById',{
                columnId
            }).then(res =>{
                if(res.code == 200){
                    this.formData = res.data
                    if (this.formData.functionId) {
                      for (var i = 0; i < this.$refs.form.functionList.length; i++) {
                        if (this.formData.functionId == this.$refs.form.functionList[i].functionId) {
                          this.formData['functionName'] = this.$refs.form.functionList[i].functionName;
                        }
                      }
                    }
                    this.contrastStatus = true   
                    this.titleName = '修改栏目'
                    ++this.showNum
                }else{  
                    this.$Message.error({
                        content:'获取栏目详情失败',
                        background:true
                    })
                    return
                }
            })
        },
        // 更新接口
        updateRow(){
            var configJson = this.formData.configJson
              if (configJson && typeof configJson == 'string') {
                try {
                    var obj = JSON.parse(configJson);
                    if (typeof obj != 'object' || !obj) {
                        this.$Message.error({
                        background: true,
                        content: "JSON格式不正确！"
                        });
                        return
                    }
                    } catch (e) {
                    this.$Message.error({
                        background: true,
                        content: "JSON格式不正确！"
                    });
                    return
                }
            }
            this.$post('/auth/api/auth/pc/systemColumn/update',{
                ...this.formData,
                functionId:this.formData.functionId[this.formData.functionId.length - 1]
            },{'Content-Type':'Application/json'}).then(res=>{
                if(res.code == 200){
                     this.$Message.success({
                        content:'修改成功',
                        background:true
                    })
                    this.page = this.currentPage
                    this.contrastStatus = false
                    this.getList()
                }else{
                    this.$Message.error({
                        content:'修改失败',
                        background:true
                    })
                    return
                }
            })
        },

        // 关联菜单
        contextMenu(data){
            this.contextMenuStatus = true
            this.getSelectColumnByList(data)
        },
        // 管理菜单框
        changeContextMenuStatus(val){
            this.contextMenuStatus = val
        },
        getMenuTree(){
            this.$get('/auth/api/auth/pc/systemModule/cascade',{
                parentId:"-1",
                systemId:'13' || this.$route.query.systemId
            }).then(res => {
                if(res.code == 200){
                    let arr = res.dataList.map( e => {
                        if(e.hasChild == 2){
                            return {
                                title:e.name,
                                sysModuleId:e.sysModuleId,
                                checked:false,
                                // disabled:true,
                                children:[],
                                loading: false,
                            }
                        }else{
                            return {
                                title:e.name,
                                sysModuleId:e.sysModuleId,
                                checked:false,
                                // disabled:true,
                            }
                        }
                    })
                    this.checkMenuTree.map( e=> {
                        arr.map( item => {
                            if(e.sysModuleId == item.sysModuleId)
                            item.checked = true
                        })
                    })
                    
                    arr.map( e=> {
                        let num = 0
                        this.checkMenuTree.map( item => {
                            if(e.sysModuleId == item.sysModuleId)
                            num ++
                        })
                        if(!num)
                        e.disabled = true
                    })
                    this.menuTree = arr
                    console.log(this.menuTree)
                }
            })
        },
        // 获取关联菜单列表
        getSelectColumnByList(data){
            this.$get('/auth/api/auth/pc/systemColumn/selectColumnMenus',{
                columnId:data.sysColumnId
            }).then( res => {
                if(res.code == 200){
                    this.checkMenuTree = res.dataList
                    this.contextNum = res.dataList.length
                    this.getMenuTree()
                }else {

                }
            })
        },
        loadMenuData(item,callback){
            this.$get("/auth/api/auth/pc/systemModule/cascade", {
                parentId: item.sysModuleId,
                systemId:'13' || this.$route.query.systemId
            }).then( res => {
                if( res.code == 200){
                    let arr = res.dataList.map( e => {
                        if(e.hasChild == 2){
                            return {
                                title:e.name,
                                sysModuleId:e.sysModuleId,
                                checked:false,
                                // disabled:true,
                                children:[],
                                loading: false,
                            }
                        }else{
                            return {
                                title:e.name,
                                sysModuleId:e.sysModuleId,
                                checked:false,
                                // disabled:true,
                            }
                        }
                    })
                    this.checkMenuTree.map( e=> {
                        arr.map( item => {
                            if(e.sysModuleId == item.sysModuleId)
                            item.checked = true
                        })
                    })

                    
                    item.children = arr;
                    callback(item.children);
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
/deep/#toolsbarRight {
  width: 100%;
}
// /deep/.ivu-modal-close {
//   display: none;
// }
/deep/ #modal_contentarea{
        overflow: visible !important;
}
</style>