<template>
  <div>
       <Form
        :model="formData"
        :label-colon="true"
        :label-width="100"
         ref="form"
      >
        <FormItem>
          <span slot="label" class="validate">栏目名称:</span>
          <Input style="width:300px" :disabled="Type == 'check'" v-model.trim="formData.columnName" :maxlength="50" placeholder="请填写信息名称"></Input>
        </FormItem>
        <FormItem>
          <span slot="label" class="validate">栏目类型:</span>
          <Input style="width:300px" :disabled="Type == 'check'" v-model.trim="formData.columnType" :maxlength="50" placeholder="请填写栏目名称"></Input>
        </FormItem>
        
        <FormItem v-if="showCascader && ziShow != false && editType!='add'" >
          <span slot="label" class="validate">系统ID:</span>
          <Cascader @on-change="changeScader" change-on-select v-model="formData.systemId" style="width:200px;margin-right:10px;display:inline-block;text-align:left" :data="cascaderList" :load-data="loadData"></Cascader>
        </FormItem>
        <FormItem v-if="titleName == '修改栏目' && showCascader == false && ziShow != false">
            <span slot="label" class="validate">系统ID:</span>
            <Tag closable @on-close="handleClose">{{formData.systemName || '未知'}}</Tag>
        </FormItem>
        <FormItem>
          <span slot="label" class="validate">属性值:</span>
          <Input style="width:300px" :disabled="Type == 'check'" v-model.trim="formData.extendCode" :maxlength="50" placeholder="请填写属性值"></Input>
        </FormItem>
        <FormItem>
          <span slot="label" class="validate">客户端类型:</span>
            <Select v-model="formData.clientType" style="width:200px;text-align:left;margin-right:10px" placeholder="请选择客户端类型">
              <Option v-for="(item,index) in clientList" :value="item.dictKey" :key="index">{{
                  item.dictValue
                }}
              </Option>
            </Select>
          </FormItem>
          <FormItem v-if="showFunctionCascader && ziShow != false" prop="functionId">
          <span slot="label" >功能ID:</span>
          <Cascader
              style="margin-right: 10px;width:200px"
              v-model="formData.functionId"
              :data="childrenList"
              @on-change="changeFunctionScader"
              change-on-select
          />
        </FormItem>
         <FormItem  v-if="titleName == '修改栏目' && showFunctionCascader == false && ziShow != false">
           <span slot="label" >功能ID:</span>
           <Tag closable @on-close="handleFunctionClose">{{formData.functionName || '未知'}}</Tag>
         </FormItem>

        <FormItem label="JSON配置" prop="configJson">
          <!-- <Input style="width:300px" :disabled="Type == 'check'" v-model.trim="formData.configJson" :maxlength="50" placeholder="请填写属性值"></Input> -->
          <Input style="width:300px" rows="5" v-model="formData.configJson" type="textarea"   />
        </FormItem>
        
      </Form>
  </div>
</template>

<script>
export default {
    props:['showNum','formData','titleName','editType'],
    data(){
        return{
            childrenList:[],
            functionList:[],
            businessList:[],
            formDataValidate: {
                    columnName: [
                        { required: true, message: '信息名称不能为空', trigger: 'blur' }
                    ],
                    columnType: [
                        { required: true, message: '栏目类型不能为空', trigger: 'change' }
                    ],
                    extendCode: [
                        { required: true, message: '属性值不能为空', trigger: 'blur' }
                    ], 
                    clientType: [
                        { required: true, message: '客户端类型不能为空', trigger: 'blur' }
                    ],
                    functionId: [
                        { required: true, message: '功能ID不能为空', trigger: 'blur' }
                    ],
           },
           cascaderList:[],

           showCascader:true,
           showFunctionCascader: true,
           ziShow:true,

           // 客户端类型
           clientList:[],
        }
    },
    async created() {
      this.getChildrenList();
      // this.get
    },
    methods:{
        // 方法部分
        // callback级联函数
          loadData (item, callback) {
               item.loading = true
               this.$get('/gx/pc/organization/orgSystem/selectSystemList',{
                    keyword:'',
                    parentId:item.value
                }).then(res=>{
                    if(res.code == 200){
                        let arr = res.dataList.map(item=>{
                            return {
                                value:item.systemId,
                                label:item.appName,
                                parentId:item.parentId,
                                appCode:item.appCode
                            }
                        })
                        item.loading = false
                        item.children = arr
                        callback(item.children)
                    }else{
                        callback([])
                    }
                })
                
         },
         changeScader(val){
             this.formData.systemId = val
         },
         changeFunctionScader(val) {
           this.formData.functionId = val
         },
         handleClose(){
             this.showCascader = true
             this.formData.systemId = []
         },
         handleFunctionClose(){
            this.showFunctionCascader = true
            this.formData.functionId = []
         },
        // 接口部分
        // 数字字典接口
        getDictTye(dictType,list){
            this.$get("/voteapi/api/common/sys/findDictByDictType", {
                dictType
            }).then(res => {
                if(res.code == 200) {
                    this[list]= res.dataList
                }
            })
        },
        getDictTyet(dictType,list){
            this.$get("/dictlist/lf-common/api/lfcommon/pc/dict/selectDictDataList", {
                dictType,
                appCode:""
            }).then(res => {
                if(res.code == 200) {
                    this[list]= res.dataList
                }
            })
        },
        //获取级联
        getCasacader(){
            this.$get('/gx/pc/organization/orgSystem/selectSystemList',{
                keyword:'',
                parentId:0
            }).then(res=>{
                if(res.code == 200){
                    this.cascaderList = res.dataList.map(item=>{
                        if(item.hasChild == '2'){
                            return {
                                value:item.systemId,
                                label:item.appName,
                                children:[],
                                loading: false,
                                parentId:item.parentId,
                                appCode:item.appCode
                            }
                        }else{
                            return {
                                value:item.systemId,
                                label:item.appName,
                                parentId:item.parentId,
                                appCode:item.appCode
                            }
                        }
                        
                    })
                }else{
                    this.$Message.error({
                        content:'获取级联选择失败',
                        background:true
                    })
                    return
                }
            })
        },

      //递归改children
      dg(children){
        for (var i = 0; i < children.length; i++) {
          this.functionList.push(children[i]);
          children[i]['label'] = children[i].functionName;
          children[i]['value'] = children[i].functionId;
          if (children[i].children) {
            this.dg(children[i].children);
          } else {
            delete(children[i]['children'])
          }
        }
      },

      // 获取全部功能和子级
      getChildrenList(){
        this.$get("/auth/api/auth/pc/systemFunction/getAllFunctionsAndChildren", {
        }).then(res => {
          if (res.code == 200) {
            this.childrenList = res.dataList;
            //处理成label，value
            this.dg(this.childrenList);
          }
        });
      },
    },
    watch:{
        showNum(){
            this.getDictTyet('COLUMN_CLIENT_TYPE','clientList')
            this.getDictTye('MENU_BUSINESS','businessList')
            this.getCasacader()
            if(this.titleName == '修改栏目') {this.showCascader = false; this.showFunctionCascader = false }
            else if(this.titleName == '新增栏目') this.showCascader = true, this.showFunctionCascader = true, this.ziShow = true;
            else if(this.titleName == '新增子栏目') this.ziShow = false
            else if(this.titleName == '修改子栏目')  this.showCascader = false, this.showFunctionCascader = false, this.ziShow = false;
        }
    }
}
</script>

<style lang="less" scoped>
/deep/.validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
</style>